import { useContext } from 'react';
import PriceWrapper from 'components/Common/PriceCTA/PriceWrapper';
import Image from 'components/Layout/Module/Image';
import { ENVIRONMENT } from 'utils/constants';
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/nextjs';
import sendEvent from 'utils/experimentation';
import classNames from 'classnames';
import GlobalContext from 'utils/context/GlobalContextProvider';
import styles from './CartUpsell.module.scss';

export default function CartUpsell({ product, ctaUrl }) {
    const globalContext = useContext(GlobalContext),
        upsellProduct = globalContext.upsell,
        CTA = dynamic(import('components/Tailwind/Components/CTA'), {
            ssr: false,
        }),
        { productImage, name, sku } = product,
        alternativeProductData = upsellProduct || {},
        addons = alternativeProductData?.addonsCollection.items,
        ctaSection = (mobile = false) => (
            <div className={classNames(mobile && styles.mobile, styles.ctas)}>
                <CTA
                    data={{
                        style: 'Secondary',
                        mode: 'Dark',
                        text: 'Order & start your plan',
                        product: alternativeProductData,
                        layout: {
                            class: ['wide'],
                        },
                        pricing: ['Hide Sales Message'],
                    }}
                    position={`Upsell Accept - ${sku} - ${
                        mobile ? 'mobile' : 'desktop'
                    }`}
                />
                <CTA
                    data={{
                        style: 'Transparent',
                        mode: 'Light',
                        text: 'Continue with test only',
                        product,
                        layout: {
                            class: ['wide'],
                        },
                        pricing: ['Hide Sales Message'],
                        disableUpsell: true,
                    }}
                    position={`Upsell Reject - ${sku} - ${
                        mobile ? 'mobile' : 'desktop'
                    }`}
                />
            </div>
        ),
        ctaArrow = (desktop = false) => (
            <div
                className={classNames(desktop ? styles.desktop : styles.mobile)}
            >
                <CTA
                    data={{
                        style: 'ForwardArrow',
                        mode: 'Light',
                        text: 'Proceed to checkout',
                        url: ctaUrl,
                        product,
                        pricing: ['Hide Sales Message'],
                        disableUpsell: true,
                    }}
                    position={`Upsell Proceed to Checkout - ${sku} - ${
                        desktop ? 'desktop' : 'mobile'
                    }`}
                />
            </div>
        );

    if (ENVIRONMENT === 'production' && ctaUrl.includes('staging-buy')) {
        Sentry.captureMessage('Staging URL in Production Detected', {
            extra: {
                url: ctaUrl,
                position: 'Cart Upsell',
            },
        });
        ctaUrl = ctaUrl.replace('staging-buy', 'buy');
    }

    sendEvent('upsell_launched', sku);

    return (
        <div className={classNames(styles.wrapper, 'pt-4 md:p-0')}>
            <div className={styles.body}>
                <div className={(styles.base, 'p-4 md:p-8 lg:p-10')}>
                    <div
                        className={classNames(
                            styles.head,
                            'mb-4 md:mb-8 lg:mb-6',
                        )}
                    >
                        {ctaArrow(false)}
                        <div
                            className={classNames(
                                styles.headline,
                                'flex items-center gap-4 align-middle',
                            )}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M22.3613 3.64228L9.54595 21.5376L9.14033 22.104L8.64032 21.6188L1.93359 15.1115L2.76923 14.2502L8.97596 20.2725L21.3857 2.9436L22.3613 3.64228Z"
                                    fill="#009E82"
                                />
                            </svg>
                            <p>Added to cart!</p>
                        </div>
                    </div>
                    <div className={classNames(styles.product, 'mb-4')}>
                        <Image
                            data={productImage}
                            options={{
                                width: 120,
                                height: 120,
                                quality: 100,
                                layout: 'fixed',
                                align: 'left',
                            }}
                        />
                        <div className={classNames(styles.top)}>
                            <p className={styles.name}>{name}</p>
                            <p className={classNames(styles.quantity, 'mb-2')}>
                                Quantity: 1
                            </p>
                            <PriceWrapper
                                product={product}
                                pricingMode="Light"
                                showTax={false}
                                showDiscount
                            />
                        </div>
                        <p className={styles.description}>
                            Your test kit, equipped with all the essentials for
                            convenient at-home sample collection, is the first
                            step towards a profoundly personalized health
                            transformation.
                        </p>
                    </div>
                    {ctaArrow(true)}
                </div>

                <div
                    className={classNames(styles.upsell, 'p-4 md:p-8 lg:p-10')}
                >
                    <div className={styles.message}>
                        20% off your test and subscription for 3 months with
                        code: LEAP
                    </div>
                    {alternativeProductData && (
                        <div className={classNames(styles.product, 'mb-4')}>
                            {alternativeProductData?.sku && (
                                <Image
                                    data={alternativeProductData.productImage}
                                    options={{
                                        width: 120,
                                        height: 120,
                                        quality: 100,
                                        layout: 'fixed',
                                        align: 'left',
                                    }}
                                />
                            )}
                            <div className={styles.top}>
                                <p className={styles.name}>
                                    {alternativeProductData?.displayName ||
                                        alternativeProductData?.name}
                                </p>
                                <PriceWrapper
                                    product={alternativeProductData}
                                    isSubscription
                                    pricingMode="Light"
                                    showTax={false}
                                    showDiscount={false}
                                />
                            </div>
                            <p
                                className={classNames(
                                    styles.description,
                                    styles.additional,
                                    'mt-2',
                                )}
                            >
                                Your at-home test kit is included with this plan
                            </p>
                        </div>
                    )}
                    {ctaSection(true)}
                    <div className={classNames(styles.details, 'my-4')}>
                        <p className={classNames(styles.includes, 'mb-4')}>
                            Your plan includes:
                        </p>
                        {addons && addons.length > 0 && (
                            <ul className={classNames(styles.addons, 'pb-4')}>
                                {addons.map((addon) => (
                                    <li>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M15.1109 1.58419L5.68927 14.7404L5.35125 15.2124L4.93458 14.8081L0.00390625 10.0241L0.700264 9.30637L5.21427 13.6862L14.2979 1.00195L15.1109 1.58419Z"
                                                fill="#146464"
                                            />
                                        </svg>
                                        <div>
                                            <p className={styles.name}>
                                                {addon.name}
                                            </p>
                                            <p className={styles.misc}>
                                                {addon.countMessage}
                                            </p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                        <ul className={classNames(styles.more, 'my-4')}>
                            <li>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0 7.5H16V8.5H0V7.5Z"
                                        fill="#146464"
                                    />
                                </svg>
                                Customized formulation based on your test
                                results.
                            </li>
                            <li>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0 7.5H16V8.5H0V7.5Z"
                                        fill="#146464"
                                    />
                                </svg>
                                Targets underlying activity with potent
                                ingredients, clinically validated ingredients
                            </li>
                            <li>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0 7.5H16V8.5H0V7.5Z"
                                        fill="#146464"
                                    />
                                </svg>
                                Effective formulas that work synergistically
                                with your food recommendations
                            </li>
                        </ul>
                    </div>
                    <p
                        className={classNames(
                            styles.disclaimer,
                            'mb-10 mt-4 pt-4',
                        )}
                    >
                        <sup>*</sup> 30-day supply delivered monthly. Pause,
                        change order date, or cancel anytime after 3 months.
                    </p>
                    {ctaSection(false)}
                </div>
            </div>
        </div>
    );
}
