/* eslint-disable import/no-self-import */
import { useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import classNames from 'classnames';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import RowContextProvider from 'utils/context/RowContextProvider';
import { cBoolean, parseModules, InfoIcon as Info } from '@viome/vnxt-nucleus';
import TailwindRow from 'components/Tailwind/Row';
import AutoPlayVideo from 'components/Layout/Utils/AutoPlayVideo';
import Popup from './Components/PopupGeneric';
import Modules from './Modules';
import layoutClasses from './Utils/LayoutClasses';
import MultiColumnClasses from './Utils/MultiColumnClasses';
import styles from './Row.module.scss';

const Row = ({ data }) => {
    const {
            id,
            name,
            mobileColumns,
            tabletColumns,
            desktopColumns,
            columnStyles,
            columnGap,
            verticalAlignment,
            modules,
            mode,
            marginBottom,
            marginTop,
            displayWidth,
            full,
            layout,
            borderColor,
            backgroundColor,
            backgroundImageMedium,
            backgroundImageSmall,
            backgroundImageXl,
            backgroundQuality,
            backgroundVideo,
            isBackgroundAttached,
            videoRatio,
            heights,
            scrollable,
            renderAtDesktop,
            renderAtMobile,
            renderAtTablet,
            url,
            popup,
            __typename,
            sys,
            centerPopup,
            isHoverSection = false,
        } = data,
        rowID = __typename + (sys?.id || id),
        moduleItems = modules?.items || parseModules(modules),
        darkMode = mode === 'Dark',
        renderConfig = [
            !renderAtMobile && 'Mobile',
            !renderAtTablet && 'Tablet',
            !renderAtDesktop && 'Desktop',
        ],
        showSection =
            renderConfig &&
            renderConfig.map((vp) => {
                if (vp) {
                    return `hide${vp}`;
                }
                return '';
            }),
        hasBackgroundImg =
            backgroundImageSmall || backgroundImageMedium || backgroundImageXl,
        videoData = backgroundVideo?.fields.file,
        [hover, setHover] = useState(false),
        [active, setActive] = useState(false),
        layoutOptions = layoutClasses(
            layout,
            borderColor,
            styles,
            backgroundColor,
        ),
        inlineStyles = {},
        actionAttr = {},
        onClick = () => {
            setActive(true);
        },
        onClose = () => {
            setActive(false);
        },
        hasHoverVideo = layout?.class?.includes('hoverVideo'),
        parsedPopup = popup && parseModules([popup])[0],
        RowInlineStyles = dynamic(
            import('components/Layout/Utils/RowInlineStyles'),
            {
                ssr: false,
            },
        );

    if (hasHoverVideo) {
        actionAttr.onMouseEnter = () => setHover(true);
        actionAttr.onMouseLeave = () => setHover(false);
    } else if (popup) {
        actionAttr.onClick = () => onClick();
        actionAttr.onKeyPress = () => onClick();
    }

    return (
        <ConditionalWrapper
            condition={cBoolean(url) || isHoverSection}
            wrapper={(nodeChildData) => {
                if (isHoverSection) {
                    return (
                        <div className={styles.hoverWrapper}>
                            {nodeChildData}
                        </div>
                    );
                }
                return (
                    <Link href={url} className={styles.clickable}>
                        {nodeChildData}
                    </Link>
                );
            }}
        >
            {(heights || videoData || hasBackgroundImg) && (
                <RowInlineStyles
                    heights={heights}
                    rowID={rowID}
                    videoRatio={videoData && (videoRatio || 0.6)}
                    hasHoverVideo={hasHoverVideo}
                    bgs={
                        videoRatio && !hasHoverVideo
                            ? {}
                            : {
                                  MOBILE: backgroundImageSmall,
                                  TABLET: backgroundImageMedium,
                                  XL: backgroundImageXl,
                              }
                    }
                    backgroundQuality={backgroundQuality}
                    pixelDensity={2}
                    layout={layout}
                />
            )}
            <section
                {...actionAttr}
                id={id}
                className={classNames(
                    styles.row,
                    'row',
                    rowID,
                    marginBottom && styles['margin-bottom'],
                    marginTop && styles['margin-top'],
                    darkMode && `${styles.dark} dark`,
                    hasBackgroundImg && styles.bg,
                    isBackgroundAttached && styles['bg--attached'],
                    backgroundVideo && styles['bg--video'],
                    cBoolean(scrollable) && styles.scrollable,
                    cBoolean(full) && styles.full,
                    'viome3--section',
                    styles[id],
                    [displayWidth, styles[displayWidth]],
                    isHoverSection && styles.hover,
                    hover && hasHoverVideo && styles.hoverVideo,
                    !isHoverSection && popup && styles.hasPopup,
                    styles[__typename],
                    layoutOptions.rowClasses,
                    showSection,
                )}
                style={{ ...inlineStyles, ...layoutOptions.rowStyles }}
            >
                {backgroundVideo &&
                    (!hasHoverVideo || (hasHoverVideo && hover)) && (
                        <AutoPlayVideo
                            src={`https:${videoData?.url}`}
                            cls={styles.videoBackground}
                        />
                    )}
                <div
                    className={classNames(
                        layoutOptions.classes,
                        styles.row__container,
                        'row__container',
                        MultiColumnClasses(
                            [
                                ['sm', mobileColumns],
                                ['md', tabletColumns],
                                ['lg', desktopColumns],
                            ],
                            columnStyles,
                            columnGap,
                            verticalAlignment,
                            styles,
                        ),
                    )}
                    style={layoutOptions.styles}
                >
                    <StyleContextProvider styles={styles}>
                        <RowContextProvider data={{ position: name }}>
                            <Modules
                                modules={moduleItems}
                                styles={styles}
                                columns={{
                                    mobileColumns,
                                    tabletColumns,
                                    desktopColumns,
                                }}
                                columnStyles={columnStyles}
                                darkMode={darkMode}
                                wrap={false}
                                rowLayout={layout}
                            />
                        </RowContextProvider>
                    </StyleContextProvider>
                </div>
            </section>
            {parsedPopup && (
                <ConditionalWrapper
                    condition={active && !isHoverSection}
                    wrapper={(nodeChildData) => (
                        <Popup
                            active={false}
                            title=""
                            className="heading-5 heading"
                            icon={<Info />}
                            useCTA
                            closeState={onClose}
                            centerPopup={centerPopup}
                            backgroundColor={parsedPopup.backgroundColor}
                        >
                            {nodeChildData}
                        </Popup>
                    )}
                    elseWrapper={(nodeChildData) => {
                        if (isHoverSection) {
                            return (
                                <div className={styles.hoverSection}>
                                    {nodeChildData}
                                </div>
                            );
                        }
                        return null;
                    }}
                >
                    <TailwindRow data={parsedPopup} />
                </ConditionalWrapper>
            )}
            {marginBottom && <hr className={styles.separator} />}
        </ConditionalWrapper>
    );
};

export default Row;
