import React, { useContext } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import sendEvent from 'utils/experimentation';
import { parseURL } from '@viome/vnxt-nucleus';
import { StyleContext } from 'utils/context/StyleContextProvider';
import Image from 'components/Layout/Module/Image';

export default function NavLinks({ nestedLinks, linkAttr, callback }) {
    if (nestedLinks) {
        const styles = useContext(StyleContext),
            linkProps = { ...linkAttr },
            allLinks = [],
            ctaClick = (e, linkTitle, ctaOptions) => {
                sendEvent('cta_click', linkTitle, ctaOptions);
                callback();
            },
            parseLink = (nestedLinkData) => {
                const linkSlug = nestedLinkData?.slug,
                    linkURL = linkSlug
                        ? `/products/${linkSlug}`
                        : nestedLinkData.url,
                    linkTitle =
                        nestedLinkData.linkText ||
                        nestedLinkData.title ||
                        nestedLinkData.name,
                    linkBackground = nestedLinkData?.backgroundImage?.fields,
                    linkEl = (
                        <Link
                            href={parseURL(linkURL)}
                            key={linkTitle}
                            onClick={(e) => ctaClick(e, linkTitle, linkProps)}
                            onKeyDown={(e) => ctaClick(e, linkTitle, linkProps)}
                            role="link"
                            tabIndex={0}
                            className={classNames(
                                styles.viomeNav__link,
                                styles['viomeNav__link--dropdown'],
                                linkBackground && styles['has-bg'],
                            )}
                            {...linkProps}
                        >
                            <span>{linkTitle}</span>
                            <Image
                                data={linkBackground}
                                options={{
                                    priority: true,
                                    quality: 100,
                                    layout: 'fill',
                                }}
                            />
                        </Link>
                    );
                allLinks.push(linkEl);
            };

        nestedLinks.forEach((nestedLink, index) => {
            const nestedLinkData = nestedLink?.fields;
            parseLink(nestedLinkData, index);
        });

        return (
            <div
                className={classNames(
                    styles['viomeNav__dropdown-section--links'],
                )}
            >
                {allLinks}
            </div>
        );
    }
}
