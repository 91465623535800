import classNames from 'classnames';
import Link from 'next/link';
import { useContext } from 'react';
import { StyleContext } from 'utils/context/StyleContextProvider';
import NavItem from './NavItem';

export default function NavMenu({
    topLinks,
    compactNav,
    mobile,
    supportURL,
    hoverProps,
}) {
    const styles = useContext(StyleContext),
        linkAttr = {
            'data-position': 'nav',
        };

    return (
        <div className={styles.viomeNav__topLinks}>
            <ul className={styles.viomeNav__main}>
                {compactNav ? (
                    <NavItem
                        linkData={{
                            url: '/products',
                            title: 'Products',
                        }}
                        hoverProps={hoverProps}
                    />
                ) : (
                    topLinks.map((topLink) => {
                        const linkData = topLink?.fields;
                        return (
                            <NavItem
                                linkData={linkData}
                                key={linkData.title}
                                hoverProps={hoverProps}
                                location={mobile ? 'mobile' : 'desktop'}
                            />
                        );
                    })
                )}
            </ul>
            {mobile && (
                <div className={styles.viomeNav__bottomLinks}>
                    <p>My Viome</p>
                    <Link
                        href="https://my.viome.com/"
                        className={classNames(styles.viomeNav__btn)}
                        {...linkAttr}
                    >
                        Login
                    </Link>
                    <Link
                        href="https://my.viome.com/registration/welcome"
                        className={classNames(styles.viomeNav__btn)}
                        {...linkAttr}
                    >
                        Register
                    </Link>
                    {supportURL && (
                        <Link
                            href={supportURL}
                            className={classNames(styles.viomeNav__btn)}
                            {...linkAttr}
                        >
                            Support
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
}
