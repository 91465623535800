import React, { useEffect, useRef, useState, useContext } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import Router from 'next/router';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import { cBoolean } from '@viome/vnxt-nucleus';
import { StyleContext } from 'utils/context/StyleContextProvider';
import NavLinks from './NavLinks';

export default function NavItem({ location, linkData, hoverProps }) {
    const styles = useContext(StyleContext),
        dropdownRef = useRef(null),
        [isActive, setIsActive] = useState(false),
        toggleHandler = (e) => {
            setIsActive(!isActive);
            e.preventDefault();
        },
        toggleOff = () => setIsActive(false),
        nestedLinks = linkData?.dropdownLinks,
        itemURL = linkData?.url,
        itemTitle = linkData?.title,
        itemText = linkData?.linkText || itemTitle,
        hasNested = nestedLinks && nestedLinks.length > 0,
        linkAttr = {
            'data-position': 'nav',
        },
        linkSections = [],
        renderLinkSections = () => {
            if (linkData) {
                for (let i = 1; i <= 3; i++) {
                    const sectionKey = i === 1 ? '' : i,
                        sectionLinks = linkData[`dropdownLinks${sectionKey}`],
                        sectionHeader = linkData[`dropdownHeader${sectionKey}`],
                        sectionSubText =
                            linkData[`dropdownSubText${sectionKey}`];

                    if (sectionLinks && sectionLinks.length > 0) {
                        linkSections.push(
                            <div
                                className={classNames(
                                    styles['viomeNav__dropdown-section'],
                                    itemTitle === 'Products' &&
                                        sectionHeader === 'Health Tests' &&
                                        styles.tests,
                                )}
                                key={`${itemText}-${sectionKey}`}
                                id={`${itemText}-${sectionKey}-${location}`}
                            >
                                {sectionHeader && (
                                    <p
                                        className={classNames(
                                            styles[
                                                'viomeNav__dropdown-section--header'
                                            ],
                                        )}
                                    >
                                        {sectionHeader}
                                    </p>
                                )}
                                {sectionSubText && (
                                    <p
                                        className={classNames(
                                            styles[
                                                'viomeNav__dropdown-section--subtext'
                                            ],
                                        )}
                                    >
                                        {sectionSubText}
                                    </p>
                                )}
                                <NavLinks
                                    nestedLinks={sectionLinks}
                                    linkAttr={linkAttr}
                                    callback={toggleOff}
                                />
                            </div>,
                        );
                    }
                }
            }
        };

    renderLinkSections();

    useEffect(() => {
        const handleRouteChange = () => {
            setIsActive(false);
        };

        Router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    let menuProps = {};
    if (hasNested) {
        menuProps = {
            onClick: toggleHandler,
            onMouseEnter: () => hoverProps.onItemEnter(dropdownRef),
            onMouseLeave: () => hoverProps.onItemLeave(dropdownRef),
            onKeyPress: toggleHandler,
            role: 'link',
            tabIndex: 0,
        };
    }

    const navItemProps = {
        ...menuProps,
        className: classNames(
            styles.viomeNav__link,
            (isActive || hoverProps.expandedItem === dropdownRef) &&
                styles.active,
        ),
        ...linkAttr,
    };

    return (
        <li
            className={classNames(
                styles.viomeNav__item,
                hasNested && styles.click,
            )}
        >
            <ConditionalWrapper
                condition={cBoolean(itemURL)}
                wrapper={(children) => (
                    <Link href={itemURL} {...navItemProps}>
                        {children}
                    </Link>
                )}
                elseWrapper={(children) => (
                    <div {...navItemProps} id={`${children}-navItem-${location}`}>
                        {children}
                    </div>
                )}
            >
                {itemText}
            </ConditionalWrapper>
            {hasNested && (
                <div
                    ref={dropdownRef}
                    className={classNames(styles['viomeNav__dropdown-menu'])}
                >
                    <div
                        className={classNames(
                            styles['viomeNav__dropdown-links'],
                            `md:grid lg:grid-cols-${linkSections.length} lg:gap-20`,
                            linkSections.length > 1 &&
                                'md:grid-row-2 md:grid-cols-2 md:gap-x-12 md:gap-y-6',
                        )}
                    >
                        {linkSections}
                    </div>
                </div>
            )}
        </li>
    );
}
