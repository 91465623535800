export const getMousePosition = (mouseEvent) => {
    let pos = { x: 0, y: 0 };
    if (mouseEvent.pageX || mouseEvent.pageY) {
        pos = { x: mouseEvent.pageX, y: mouseEvent.pageY };
    } else if (mouseEvent.clientX || mouseEvent.clientY) {
        pos = {
            x:
                mouseEvent.clientX +
                document.body.scrollLeft +
                document.documentElement.scrollLeft,
            y:
                mouseEvent.clientY +
                document.body.scrollTop +
                document.documentElement.scrollTop,
        };
    }
    return pos;
};

export const getRectangle = (elem) => {
    const boundingBox = elem?.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (boundingBox === undefined) return null;
    return {
        topLeft: {
            x: boundingBox.left + scrollLeft,
            y: boundingBox.top + scrollTop,
        },
        topRight: {
            x: boundingBox.left + scrollLeft + boundingBox.width,
            y: boundingBox.top + scrollTop,
        },
        bottomLeft: {
            x: boundingBox.left + scrollLeft,
            y: boundingBox.top + scrollTop + boundingBox.height,
        },
        bottomRight: {
            x: boundingBox.left + scrollLeft + boundingBox.width,
            y: boundingBox.top + scrollTop + boundingBox.height,
        },
    };
};

export const isInsideRectangle = (rectangle, point) => {
    const { topLeft, topRight, bottomLeft } = rectangle || {};
    return (
        point.x >= topLeft.x &&
        point.x <= topRight.x &&
        point.y >= topLeft.y - 10 &&
        point.y <= bottomLeft.y - 40
    );
};
